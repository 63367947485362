// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".refresh_refresh-btn__gVfv9 {\n  background-color: transparent;\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  top: 5%;\n  right: 0%;\n  gap: 0;\n  box-shadow: unset;\n  z-index: 1000;\n}\n", "",{"version":3,"sources":["webpack://./src/components/refresh/refresh.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,OAAO;EACP,SAAS;EACT,MAAM;EACN,iBAAiB;EACjB,aAAa;AACf","sourcesContent":[".refresh-btn {\n  background-color: transparent;\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  top: 5%;\n  right: 0%;\n  gap: 0;\n  box-shadow: unset;\n  z-index: 1000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refresh-btn": "refresh_refresh-btn__gVfv9"
};
export default ___CSS_LOADER_EXPORT___;
