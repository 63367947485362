// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "footer.footer_footer-container__Il2IH {\n  position: fixed;\n  bottom: 5px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 2%;\n  font-size: 0.6em;\n  font-family: 'WorkSans Extralight';\n  width: 100%;\n  height: 2em;\n  z-index: -1;\n}\n.footer_footer-imgContainer__LLPWW{\n  height: 25px;\n}\n.footer_footer-imgContainer__LLPWW > img {\n  height: 100%;\n  object-fit: contain;\n}", "",{"version":3,"sources":["webpack://./src/components/footer/footer.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,OAAO;EACP,gBAAgB;EAChB,kCAAkC;EAClC,WAAW;EACX,WAAW;EACX,WAAW;AACb;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":["footer.footer-container {\n  position: fixed;\n  bottom: 5px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 2%;\n  font-size: 0.6em;\n  font-family: 'WorkSans Extralight';\n  width: 100%;\n  height: 2em;\n  z-index: -1;\n}\n.footer-imgContainer{\n  height: 25px;\n}\n.footer-imgContainer > img {\n  height: 100%;\n  object-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer-container": "footer_footer-container__Il2IH",
	"footer-imgContainer": "footer_footer-imgContainer__LLPWW"
};
export default ___CSS_LOADER_EXPORT___;
