import { default as api } from "./instance";

let signalController = new AbortController();

// get the data of page visitor count
export const page_visit = async () => {
  try {
    const response = await api.get("/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

// cancel the convert api
export const cancel_convert = async () => {
  try {
    const response = await api.post("/cancel");
    // aborting the convert api
    signalController.abort();
    // recreating instance of the abortcontroller
    signalController = new AbortController();

    return response.data;
  } catch (error) {
    throw error;
  }
};

// convert the uploaded file
export const convert_file = async (values) => {
  let formData = new FormData();
  // debugger;
  Object.keys(values).forEach((key) => formData.append(key, values[key]));

  try {
    const response = await api.post("/", values, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      signal: signalController.signal,
    });

    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
};
