import { Outlet, useLocation } from "react-router-dom";
import { Footer, NavBar } from "..";
import styles from "./layout.module.css";

export const Layout = () => {
  const {pathname} = useLocation();
  return (
    <div className={styles["page-wrapper"]}>
      {/* <div className={styles.overlay}></div> */}
      <NavBar />
      <main className={`${pathname==='/faq' ? styles['faq-main'] : ''}`}>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};
