import { default as api } from "./instance";

// convert the uploaded file
export const wordToEpub = async (values) => {
  let formData = new FormData();
  // debugger;
  Object.keys(values).forEach((key) => formData.append(key, values[key]));

  try {
    const response = await api.post("/word-to-epub", values, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
};
