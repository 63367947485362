import React, { useContext, useState } from "react";
import styles from "./reportfile.module.css";
import { X } from "react-feather";
import CheckboxInput from "../checkbox/CheckboxInput";
import { FileContext } from "../../contextAPI/fileContext";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
const ReportPageIssues = () => {
  const { themeConfig, theme, setModal, imageSource } = useContext(FileContext);
  const objectMapping = {
    textRegion: "text-region-not-detected",
    imageRegion: "image-region-not-detected",
    tableRegion: "table-region-not-detected",
    titleRegion: "title-region-not-detected",
    mathRegion: "math-region-not-detected",
  };
  const [status, setStatus] = useState({
    textRegion: false,
    imageRegion: false,
    mathRegion: false,
    titleRegion: false,
    tableRegion: false,
  });
  const checkboxChangeHandler = (key) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      [key]: !prevStatus[key],
    }));
  };
  const checkboxFormHandler = async (event) => {
    event.preventDefault();
    let newArr = [];

    for (let key in status) {
      if (status[key]) newArr.push(key);
    }
    let issuesArray = newArr.map((value) => objectMapping[value]);

    const newObj = {
      imgPath: imageSource,
      issues: issuesArray,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_SERVER}page_feedback`,
        newObj
      );
      if (response.status === 200) {
        toast.success("Reported Successfully", {
          position: "bottom-right",
          closeOnClick: true,
        });
      }
    } catch (err) {
      toast.error('Feedback already submitted' , {
        position: "bottom-right",
        closeOnClick: true,
      });
    }
  };
  const cancelReportHandler = () => {
    setModal(false);
  };
  return (
    <div
      className={styles["report-modal"]}
      style={{ background: themeConfig[theme].modalBackground }}
    >
      <button
        className={styles["report-close-btn"]}
        onClick={cancelReportHandler}
        style={{border: `2px solid ${themeConfig[theme].crossButton}`}}
        tabIndex={0}
      >
        <X style={{ color: themeConfig[theme].crossButton }} />
      </button>
      {/* text, checked, onChange */}
      <form className={styles["report-form"]} onSubmit={checkboxFormHandler}>
        <CheckboxInput
          text="Text Region Not Detected"
          checked={status.textRegion}
          onChange={() => checkboxChangeHandler("textRegion")}
        />
        <CheckboxInput
          text="Image Region Not Detected"
          checked={status.imageRegion}
          onChange={() => checkboxChangeHandler("imageRegion")}
        />
        <CheckboxInput
          text="Math Region Not Detected"
          checked={status.mathRegion}
          onChange={() => checkboxChangeHandler("mathRegion")}
        />
        <CheckboxInput
          text="Title Region Not Detected"
          checked={status.titleRegion}
          onChange={() => checkboxChangeHandler("titleRegion")}
        />
        <CheckboxInput
          text="Table Region Not Detected"
          checked={status.tableRegion}
          onChange={() => checkboxChangeHandler("tableRegion")}
        />
        <button
          type="submit"
          className={styles["form-submit-btn"]}
          style={{ background: themeConfig[theme].modalBtn }}
          tabIndex={0}
        >
          Submit
        </button>
      </form>
      <Toaster />
    </div>
  );
};

export default ReportPageIssues;
