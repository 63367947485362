import React, { useContext } from "react";
import { RotateCcw } from "react-feather";
import styles from "./refresh.module.css";
import { FileContext } from "../../contextAPI";

const RefreshBtn = ({ onClick,tabIndex=-1 }) => {
  const { themeConfig, theme } = useContext(FileContext);
  return (
    <button onClick={onClick} className={styles["refresh-btn"]} tabIndex={tabIndex}>
      <RotateCcw style={{ color: themeConfig[theme].textPrimary }} />
      <span style={{color: themeConfig[theme].textPrimary}}>Clear File</span>
    </button>
  );
};

export default RefreshBtn;
