import { createContext, useEffect, useState, useRef } from "react";
import { Outlet } from "react-router-dom";
import DarkModeLogo from "../assets/darkmode_logo.png";
import LightModeLogo from "../assets/lightmode_logo.png";
import DarkModeBulb from "../assets/darkmode_bulb.png";
import LightModeBulb from "../assets/lightmode_bulb.png";
import DarkModeFooterLogo from "../assets/darkmode_footer_logo.png";
import LightModeFooterLogo from "../assets/lightmode_footer_logo.png";
import DarkModePdfImages from "../assets/dark_pdf_images.png";
import DarkModePdfSplit from "../assets/dark_pdf_split.png";
import DarkModeWordEpub from "../assets/dark_word_epub.png";
import LightModePdfImages from "../assets/light_pdf_images.png";
import LightModePdfSplit from "../assets/light_pdf_split.png";
import LightModeWordEpub from "../assets/light_word_epub.png";
import DarkModeIocrLogo from "../assets/darkmode_iocr.png";
import LightModeIocrLogo from "../assets/lightmode_iocr.png";
import DarkBackgroundImage from '../assets/DarkBackgroundImage.webp';
import LightBackgroundImage from '../assets/LightBackgroundImage.webp'
// import DarkBackgroundDesktop from "../assets/DarkBackgroundDesktop.png";
// import LightBackgroundDesktop from "../assets/LightBackgroundDesktop.png";
// import DarkBackgroundTablet from "../assets/DarkBackgroundTablet.png";
// import LightBackgroundTablet from "../assets/LightBackgroundTablet.png";
// import DarkBackgroundMobile from "../assets/DarkBackgroundMobile.png";
// import LightBackgroundMobile from "../assets/LightBackgroundMobile.png";

const themeConfig = {
  dark: {
    logo: DarkModeLogo,
    iocr: DarkModeIocrLogo,
    footerLogo: DarkModeFooterLogo,
    bulb: DarkModeBulb,
    hoverColor: "#86EFFE",
    backgroundImages: DarkBackgroundImage,
    // backgroundImages: {
    //   mobile: DarkBackgroundMobile,
    //   tablet: DarkBackgroundTablet,
    //   desktop: DarkBackgroundDesktop
    // },
    backgroundPrimary:
      "linear-gradient(180.52deg, #052646 2.28%, #0A3464 62.25%, #113757 105.03%)",
    textPrimary: "#ffffff",
    textSecondary: "#86EFFE",
    textTertiary: "#ffffff",
    boxPrimary: "#041F36",
    boxSecondary: "#14558A",
    btnPrimary: "#0096FA",
    btnSecondary: "#61BB8B",
    btnTertiary: "#67BCFA",
    btnQuarternary: "#4A84F3",
    btnInfo: "#0A3464",
    inputBackground: "#000",
    modalBackground: "#165597",
    viewBackground: "#124575",
    viewDownload: "#4570C4",
    backHome: "#0A3464",
    reportBackground: "#114475",
    modalBtn: "#03458B",
    btnReport: "#114475",
    dropdownPrimary: "#08243D",
    dropdownSecondary: "#1A6A9F",
    dropdownHover: "#234078",
    crossButton: "#fff",
    pdf_images: DarkModePdfImages,
    pdf_split: DarkModePdfSplit,
    word_epub: DarkModeWordEpub,
  },
  light: {
    logo: LightModeLogo,
    iocr: LightModeIocrLogo,
    footerLogo: LightModeFooterLogo,
    bulb: LightModeBulb,
    hoverColor: "#0E82D7",
    backgroundImages: LightBackgroundImage,
    // backgroundImages: {
    //   mobile: LightBackgroundMobile,
    //   tablet: LightBackgroundTablet,
    //   desktop: LightBackgroundDesktop
    // } ,
    backgroundPrimary:
      "linear-gradient(180.52deg, #E0EEFB 2.28%, #D5E8FB 105.03%)",
    textPrimary: "#041F36",
    textSecondary: "#0E82D7",
    textTertiary: "#14558A",
    boxPrimary: "#D4EAFF",
    boxSecondary: "#7BBDF4",
    btnPrimary: "#4A84F3",
    btnSecondary: "#3FCE67",
    btnTertiary: "#67BCFA",
    btnQuarternary: "#4A84F3",
    btnInfo: "#7AA6FB",
    inputBackground: "#AFCAE6",
    modalBackground: "#B6D0EB",
    viewBackground: "#E7F4FF",
    viewDownload: "#4570C4",
    backHome: "#7AA6FB",
    reportBackground: "#2878DB",
    modalBtn: "#74A3DA",
    btnReport: "rgb(57 107 155)",
    dropdownPrimary: "#fff",
    dropdownSecondary: "#59ACE3",
    dropdownHover: "#D0F2FA",
    crossButton: "#74A3DA",
    pdf_images: LightModePdfImages,
    pdf_split: LightModePdfSplit,
    word_epub: LightModeWordEpub,
  },
};

// const useViewport = () => {
//   const [width, setWidth] = useState(window.innerWidth);

//   useEffect(() => {
//     const handleResize = () => setWidth(window.innerWidth);
//     window.addEventListener('resize', handleResize);

//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const isMobile = width <= 640; // Adjust breakpoints as needed
//   const isTablet = width > 640 && width <= 1024;
//   const isDesktop = width > 1024;

//   return { isMobile, isTablet, isDesktop };
// };

export const FileContext = createContext();

export const FileContextAPI = () => {
  const [file, setFile] = useState(null);
  const [isSpin, setIsSpin] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [imageSource, setImageSource] = useState("");
  const downloadActionRef = useRef([]);
  const viewRef = useRef([]);
  const downloadRef = useRef([]);
  const menuIconRef = useRef();
  const menuListRef = useRef();
  const [isOptionClicked,setIsOptionClicked] = useState(false);
  const [modal, setModal] = useState(false);
  const [fileActionOpen,setFileActionOpen] = useState(false);
  const [viewDownloadOpen,setViewDownloadOpen] = useState(false);

  
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem("themedValue");
    return savedTheme ? savedTheme : "light";
  });
  
  // const { isMobile, isTablet, isDesktop } = useViewport();

  // const backgroundImage = themeConfig[theme]?.backgroundImages[isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'];
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("themedValue", newTheme);
    setTheme(newTheme);
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(${themeConfig[theme].backgroundImages})`
    // document.body.style.backgroundImage = `url(${backgroundImage})`;
  }, [theme]);

  const [conversion, setConversion] = useState({
    isConverted: false,
    docxLocation: null,
    imgLocation: [],
    convertedFileLocation: null,
  });

  const [splitPage, setSplitPage] = useState({
    fromPage: 1,
    toPage: 1,
    totalPage: 1,
  });

  const [lang, setLang] = useState({
    name: "Select Language",
    value: "eng",
  });
  const [fileType, setFileType] = useState({
    name: "Select File Type",
    value: "text-only",
  });
  const mobileMenuHandler = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const contextValue = {
    file,
    setFile,
    lang,
    fileType,
    setLang,
    setFileType,
    splitPage,
    setSplitPage,
    conversion,
    setConversion,
    toggleTheme,
    theme,
    themeConfig,
    isSpin,
    setIsSpin,
    downloadActionRef,
    viewRef,
    downloadRef,
    isSidebarOpen,
    setIsSidebarOpen,
    mobileMenuHandler,
    menuIconRef,
    menuListRef,
    modal,
    setModal,
    imageSource,
    setImageSource,
    isOptionClicked,
    setIsOptionClicked,
    fileActionOpen,
    setFileActionOpen,
    viewDownloadOpen,
    setViewDownloadOpen
  };

  return (
    <FileContext.Provider value={contextValue}>
      <Outlet />
    </FileContext.Provider>
  );
};
