import { useContext, useEffect, useState } from "react";
import { FileContext } from "../../contextAPI";
import styles from "./fileaction.module.css";

export const FileAction = ({ title, options, selectType }) => {
  const {
    setIsOptionClicked,
    isSpin,
    themeConfig,
    theme,
    fileActionOpen,
    downloadActionRef,
  } = useContext(FileContext);

  const selectContext = useContext(FileContext);
  const [selectedValue, setSelectedValue] = useState(
    selectContext[selectType]?.value || ""
  );
  const [isOptionSelected, setIsOptionSelected] = useState(false);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = options.find((opt) => opt.value === selectedValue);

    if (selectedOption) {
      const setState =
        selectType === "lang"
          ? selectContext?.setLang
          : selectContext?.setFileType;

      setState({
        name: selectedOption.name,
        value: selectedValue,
      });

      setSelectedValue(selectedValue);
      setIsOptionClicked(true);
      setIsOptionSelected(true);
    }
  };

  useEffect(() => {
    setSelectedValue(selectContext[selectType]?.value || "");
    if (!isOptionSelected) {
      setSelectedValue("");
    }
  }, [selectContext, selectType, isOptionSelected]);

  return (
    <div className={styles["action-container"]}>
      <label
        className={styles["action-container-label"]}
        style={{ color: themeConfig[theme].textTertiary }}
      >
        {title}
      </label>

      <select
        className={styles["select-container"]}
        value={selectedValue}
        onChange={handleSelectChange}
        aria-label="Select Language"
        disabled={isSpin}
        style={{
          pointerEvents: downloadActionRef?.current?.classList?.contains(
            "fadeIn"
          )
            ? "none"
            : "",
          // background: themeConfig[theme].dropdownPrimary,
          // color: themeConfig[theme].textPrimary,
          fontWeight: "bold",
        }}
        tabIndex={
          isSpin
            ? -1
            : downloadActionRef?.current?.classList?.contains("fadeIn")
            ? -1
            : fileActionOpen
            ? 0
            : -1
        }
      >
        {/* Default option */}
        <option value="" disabled>
          Select Language
        </option>
        {/* Map options */}
        {options?.map((item) => (
          <option
            key={item.value}
            value={item.value}
            className={styles.option}
            // style={{
            //   background: themeConfig[theme].dropdownSecondary,
            //   color: themeConfig[theme].textPrimary,
            // }}
          >
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};
