import { useContext, useEffect, useRef } from "react";
import { FileContext } from "../../contextAPI";
import styles from "./viewfile.module.css";
import wordIcon from "../../assets/wordIcon.png";

export const ViewFileActions = ({ copyFileHandler }) => {
  const fileLocationContenxt = useContext(FileContext);
  const {themeConfig,theme} = useContext(FileContext);
  const selectRef = useRef(null);
  const optionsRef = useRef(null);

  useEffect(() => {
    const handleClick = (e) => {
      // toggling the option
      if (selectRef.current.contains(e.target)) {
        optionsRef.current.classList.toggle(styles["toggle-display"]);
      } else if (optionsRef.current.contains(e.target)) {
        optionsRef.current.classList.remove(styles["toggle-display"]); //hiding the options container
      } else {
        optionsRef.current.classList.remove(styles["toggle-display"]); //hiding the options container when clicked anywhere while it is still rendering
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <section tabIndex={-1} className={styles["file-actions"]}>
      {/* <div className={styles["icon-container"]} onClick={copyFileHandler}>
        <Copy stroke="#F8F8F8" />
      </div> */}

      <div className={styles["download-container"]} tabIndex={-1} style={{background: themeConfig[theme].viewDownload}}>
        {/* first option as default */}
        {/* <p className={styles.value} ref={selectRef}>
          <span className={styles.text}>Download as</span>
          <span className={styles.icon}>
            <ChevronDown color="#fff" strokeWidth={3} />
          </span>
        </p> */}
        <a
            href={fileLocationContenxt?.conversion?.docxLocation}
            download
            className={styles['download-option']}
            tabIndex={0}
          >
            <img src={wordIcon} alt="pdf icon" className={styles['word-icon']} /> 
            Download
          </a>
        {/* <div className={styles["options-container"]} ref={optionsRef}>
          
          <a
            href={fileLocationContenxt?.conversion?.docxLocation}
            download
            className={styles.option}
          >
            <img src={wordIcon} alt="pdf icon" /> Docx File
          </a> 
          <a
            href={fileLocationContenxt?.conversion?.docxLocation}
            download
            className={styles.option}
            target="_blank"
          >
            <img src={pdfIcon} alt="pdf icon" /> Pdf File
          </a>
          <a
            href={fileLocationContenxt?.conversion?.docxLocation}
            download
            className={styles.option}
          >
            <img src={textIcon} alt="pdf icon" /> Plain Text
          </a> 
        </div> */}
      </div>
    </section>
  );
};
