import { useContext } from "react";
import styles from "./footer.module.css";
import { FileContext } from "../../contextAPI/fileContext";

export const Footer = () => {
  const { theme, themeConfig } = useContext(FileContext);
  return (
    <footer className={styles["footer-container"]}>
      <span style={{color: themeConfig[theme].textPrimary,fontWeight: 'bold',fontSize: '12px'}}>Developed and Maintained By</span>
      <div className={styles['footer-imgContainer']}>
        <img src={themeConfig[theme].footerLogo} alt="footer" />
      </div>
    </footer>
  );
};
