import React, { useState, useContext } from "react";
import styles from "./faq.module.css";
import { Minus, Plus } from "react-feather";
import { FileContext } from "../../contextAPI";

const Faq = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { themeConfig, theme } = useContext(FileContext);

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
    <div className={styles['download-user-manual']}>
      <a style={{backgroundColor: themeConfig[theme].btnPrimary,paddingLeft: '10px',paddingRight: '10px',borderRadius: '5px'}} href="https://iocrfiles.olenepal.org/iocr_user_manual_english.docx" download>Download User Manual</a>
    </div>
    <div className={styles["faq-container"]}>
      <h2 style={{ color: themeConfig[theme].textPrimary }}>FAQ</h2>
      <p
        className={styles["faq-full"]}
        style={{ color: themeConfig[theme].textPrimary }}
      >
        Frequently asked questions
      </p>
      {data?.map((item, index) => (
        <div
          className={`${styles["faq-item"]} ${activeIndex === index  ? styles['active'] : ''} `}
          style={{
            borderTop:
              index === 0
                ? "none"
                : `2px solid ${themeConfig[theme].textPrimary}`,
          }}
          key={index}
          onClick={() => toggleFaq(index)}
        >
          <div className={styles["faq-question"]}>
            <p
              className={styles["faq-title"]}
              style={{ color: themeConfig[theme].textPrimary }}
            >
              {item.question}
            </p>
            <span>
              {activeIndex === index ? (
                <Minus style={{ color: themeConfig[theme].textPrimary }} />
              ) : (
                <Plus style={{ color: themeConfig[theme].textPrimary }} />
              )}
            </span>
          </div>
          {activeIndex === index && (
            <div
              className={styles["faq-answer"]}
              style={{ color: themeConfig[theme].textPrimary }}
            >
              {item.answer}
            </div>
          )}
        </div>
      ))}
    </div>
    </>
  );
};

export default Faq;
