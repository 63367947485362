import { DownloadOrConvert, DropArea, Hero } from "../components";
import { useContext, useEffect } from "react";
import { wordToEpub } from "../api";
import { FileContext } from "../contextAPI";

export const WordToEpub = () => {
  const {themeConfig,theme} = useContext(FileContext)
  useEffect(() => {
    document.title = "Word to Epub - OLEN-iOCR";
  }, []);

  return (
    <>
      <Hero
        element={
          <>
            <h1 className="title-with__images">
              <img src={themeConfig[theme].word_epub} alt="Word to Epub" height="45em" />
              <span style={{color: themeConfig[theme].textPrimary,fontSize: '1.25em'}}>Word to E-Pub</span>
            </h1>
          </>
        }
        content="Conversion process that turns Microsoft Word documents into EPub format, which is widely used for electronic books and publications. EPUB stands for electronic publication."
      />

      <DropArea fileTypes={["DOCX", "DOC"]} />

      <DownloadOrConvert convertAPI={wordToEpub} isEpub={true} />
    </>
  );
};
