import { useContext, useEffect, useState } from "react";
import axios from "axios";
import Faq from "../components/faq/Faq";
import { FileContext } from "../contextAPI";
import csvFile from '../assets/faq_spreadsheet.csv';

const parseCSV = (data) => {
  const lines = data.split("\n");
  const headers = lines[0].split(",").map((header) => header.trim());

  const result = lines.slice(1).map((line) => {
    const values = line.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g);
    return headers?.reduce((obj, header, index) => {
      obj[header] = values[index].replace(/(^"|"$)/g, "").trim().replace(/""/g,'"');
      return obj;
    }, {});
  });
  return result;
};



export const FAQPage = () => {
  const [csvData, setCsvData] = useState([]);
  const { themeConfig, theme } = useContext(FileContext);

  function fetchCSVData() {
    axios
      .get(csvFile)
      .then((response) => {
        const parsedCsvData = parseCSV(response.data);
        setCsvData(parsedCsvData);
      })
      .catch((error) => {
        console.error("Error fetching CSV data:", error);
      });
  }

  useEffect(() => {
    fetchCSVData();
  }, []);
  return (
    <>
      {csvData?.length > 0 ? (
        <Faq data={csvData} />
      ) : (
        <p
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: themeConfig[theme].textPrimary,
          }}
        >
          Loading...
        </p>
      )}
    </>
  );
};
