import React, { useContext, useState } from "react";
import { FileContext } from "../contextAPI";
import "../App.css";
import Input from "../components/Input";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

export const ContactUs = () => {
  const { themeConfig, theme } = useContext(FileContext);
  const [clientError, setClientError] = useState({
    firstname: "",
    lastname: "",
    email: "",
    message: "",
  });
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    message: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setClientError({ ...clientError, [name]: "" });
  };
  const formSubmitHandler = async (event) => {
    event.preventDefault();
    let errors = {};

    for (const key in formData) {
      if (!formData[key]) {
        errors[key] = `Enter ${key.charAt(0).toUpperCase() + key.slice(1)}`;
      }
    }
    if (Object.keys(errors).length > 0) {
      setClientError(errors);
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_SERVER}feedback`,
          formData
        );
        if(response.status === 200){
          toast.success("Report Sent Successfully", {
            position: "bottom-right",
            closeOnClick: true,
          });
        }

        setFormData({
          firstname: "",
          lastname: "",
          email: "",
          message: "",
        });
      } catch (err) {
        // console.log(err);
        //handle error
      }
    }
  };
  return (
    <section className="contact-us-wrapper">
      <div className="form-wrapper">
        <h3 style={{ color: themeConfig[theme].textPrimary }}>
          have a question? Drop us a line and we will come back to you quickly.
        </h3>
        <form onSubmit={formSubmitHandler}>
          <div className="name-wrapper">
            <Input
              label="First Name"
              inputType="text"
              name="firstname"
              value={formData.firstname}
              onChange={handleInputChange}
              err={clientError.firstname}
            />

            <Input
              label="Last Name"
              inputType="text"
              name="lastname"
              value={formData.lastname}
              onChange={handleInputChange}
              err={clientError.lastname}
            />
          </div>

          <Input
            label="Email"
            inputType="email"
            name="email"
            value={formData.email}
            err={clientError.email}
            onChange={handleInputChange}
          />

          <div className="textarea-wrapper">
            <div style={{ color: themeConfig[theme].textPrimary }}>Message</div>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              style={{
                background: themeConfig[theme].inputBackground,
                border: "none",
                color: themeConfig[theme].textPrimary,
              }}
            />
            {clientError.message && (
              <span
                style={{
                  color: "red",
                  display: "flex",
                  fontSize: "0.7em",
                  marginTop: "-13px",
                }}
              >
                {clientError.message}
              </span>
            )}
          </div>
          <div className="feedback-btn-container">
            <button className="feedback-btn">Submit</button>
          </div>
        </form>
      </div>
      <Toaster />
    </section>
  );
};
