import { DownloadOrConvert, DropArea, Hero } from "../components";
import { convertPdtToImg } from "../api";
import { useContext, useEffect } from "react";
import { FileContext } from "../contextAPI";

export const PdfToImg = () => {
  const {themeConfig,theme} = useContext(FileContext)
  useEffect(() => {
    document.title = "PDF to Images - OLEN-iOCR";
  }, []);
  return (
    <>
      <Hero
        element={
          <>
            <h1 className="title-with__images">
              <img src={themeConfig[theme].pdf_images} alt="PDF to Images" height="45em" />
              <span style={{color: themeConfig[theme].textPrimary,fontSize: '1.25em'}}>PDF to Images</span>
            </h1>
          </>
        }
        content="A PDF-to-image application is a tool that allows users to convert PDF (Portable Document Format) files into jpg image formats. This application is useful for extracting images from PDF documents or for converting entire pages of a PDF into image files. "
      />

      <DropArea />

      <DownloadOrConvert convertAPI={convertPdtToImg} />
    </>
  );
};
