import styles from "./navigation.module.css";
import { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FileContext } from "../../contextAPI";
// import { Home } from "react-feather";

export const NavItem = ({ path, content, mobileMenuHandler }) => {
  const { theme, themeConfig,isSpin } = useContext(FileContext);
  const location = useLocation();
  const isActive = location.pathname === path;
  const additionalClasses = 
    theme === "dark"
      ? styles["dark-after"]
      : styles["light-after"]
    ;
  return (
    <li aria-label={content} className={isSpin ? styles['pointer-events-none']:''}>
      <NavLink
        to={path}
        onClick={mobileMenuHandler}
        // className={`${styles["nav-link-a"]}${isActive ? styles['active'] : ""} ${
        //   path !== "/" || content?.type !== "img"
        //     ? theme === "light"
        //       ? styles["dark-after"]
        //       : styles["light-after"]
        //     : ""
        // }`}
        style={{ color: themeConfig[theme].textPrimary }}
        className={`${styles["nav-link-a"]} ${
          isActive ? styles["active"] : additionalClasses
        }`}
      >
        
            {content}
          
      </NavLink>
    </li>
  );
};
