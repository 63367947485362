import { useContext, useEffect } from "react";
import {
  DropArea,
  FileActionHandler,
  Hero,
  PageVisitSphere,
} from "../components";
import { FileContext } from "../contextAPI";

export const Home = () => {
  const {themeConfig,theme} = useContext(FileContext);
  useEffect(() => {
    document.title = "OLEN-iOCR";
  }, []);

  return (
    <>
      <Hero
        element={
          <>
            <h1 className="title-with__images">
              <img
                src={themeConfig[theme].iocr}
                alt="OLEN-iOCR"
                height="45em"
              />
              <span style={{ color: themeConfig[theme].textPrimary,fontSize: '1.25em' }}>
                OLEN-iOCR
              </span>
            </h1>
          </>
        }
        // title="OLEN-iOCR"
        content="OLE Nepal's iOCR (Intelligent Optical Character Recognition) is a web-based application that converts an image/pdf of text into a machine-readable text format. It extracts text, images, tables and math formulae from the pdf/scanned documents."
      />

      <DropArea fileTypes={["JPG", "PNG", "JPEG", "PDF"]} />

      <FileActionHandler />

      <PageVisitSphere />
    </>
  );
};
