import React, { useContext, useEffect, useRef, useState } from "react";

import styles from "./fileaction.module.css";
import { FileAction } from "./FileAction";
import { cancel_convert, convert_file } from "../../api";
import { FileContext } from "../../contextAPI";
import { Button } from "./button";
import Swal from "sweetalert2";

export const FileActionHandler = () => {
  const [showCancel, setShowCancel] = useState(false);

  const fileContext = useContext(FileContext);

  const fileActionRef = useRef([]);
  const langActionRef = useRef([]);
  const {
    downloadActionRef,
    viewRef,
    downloadRef,
    fileActionOpen,
    setViewDownloadOpen,isSpin,setIsSpin,isOptionClicked,setFileActionOpen,
  } = useContext(FileContext);

  useEffect(() => {
    // showing file type select when file is dopped or selected
    fileContext?.file != null && fileActionRef.current.classList.add("fadeIn");
    fileContext?.file != null && setFileActionOpen(true);

    // showing language select when file-type is selected
    fileContext?.fileType.value !== null &&
      langActionRef?.current.classList.add("fadeIn");
  }, [
    fileContext?.file,
    fileContext?.fileType?.value,
    fileActionRef.current,
    langActionRef.current,
  ]);

  // resetting the context in page at first load
  useEffect(() => {
    fileContext?.setFile(null);
    fileContext?.setConversion((prev) => ({ ...prev, isConverted: false }));
    fileContext?.setSplitPage((prev) => ({ ...prev, fromPage: 1, toPage: 1 }));
  }, []);

  // conversion handler
  function convertFileHandler() {
    // showing the spinner
    setIsSpin(true);

    setShowCancel(true);

    // show cancel btn
    if (document.querySelector(".btn-cancel"))
      document.querySelector(".btn-cancel").style.display =
        "block";

    // for landing page only
    // calling the conversion api
    convert_file({
      ["conversion-type"]: fileContext?.fileType.value,
      file: fileContext?.file,
      language: fileContext?.lang.value,
    })
      .then((data) => {
        fileContext?.setConversion((prev) => ({
          ...prev,
          isConverted: true,
          convertedFileLocation: data?.filename + ".docx",
          // setting converted docx location
          docxLocation:
            process.env.REACT_APP_FTP_SERVER + data?.results?.docx_file_path,
          // setting imgages location
          imgLocation: data?.results?.images?.map(
            (item) => process.env.REACT_APP_FTP_SERVER + item
          ),
        }));
        const audio = new Audio('/conversion_completed.mp3');
        audio.play();

        downloadActionRef.current.style.pointerEvents = "all";
        // making view btn accesible to accessibility API
        viewRef.current.setAttribute("aria-hidden", "false");

        // showing language select when file-type is selected
        downloadActionRef?.current.classList.add("fadeIn");
        setViewDownloadOpen(true);
        // making download btn accesible to accessibility API
        downloadRef?.current.setAttribute("aria-hidden", "false");

        // hiding the spinner
        setIsSpin(false);
        setShowCancel(false);
      })
      .catch((e) => {
        // hiding the spinner
        setIsSpin(false);
        setShowCancel(false);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: e.error,
        });

        console.error(e);
      });
  }
  // conversion cancel handler
  function cancelFileHandler() {
    // hiding the spinner
    setIsSpin(false);

    // for landing page only
    // calling the conversion cancel api
    cancel_convert();
  }

  return (
    <section className={styles["file-action__container"]} ref={fileActionRef}>
      {/* <FileAction
        title="File Type"
        options={[
          { name: "Text", value: "text-only" },
          { name: "Image & Text", value: "text-with-images" },
        ]}
        selectType="fileType"
      /> */}

      <div className={styles["convert-row"]} ref={langActionRef}>
        <div className={styles.lang}>
          <FileAction
            title="Document Language"
            options={[
              { name: "English", value: "eng" },
              { name: "Nepali", value: "nep" },
              { name: "Both", value: "nep+eng" },
            ]}
            selectType="lang"
          />
        </div>
        <div className={styles["btn-flex"]}>
          <Button
            clickHandler={convertFileHandler}
            cssStyle="btn-convert"
            btnName="Convert"
            style={{
              pointerEvents: isSpin
                ? "none"
                : downloadActionRef?.current?.classList?.contains("fadeIn")
                ? "none"
                : !isOptionClicked
                ? "none"
                : "unset",
            }}
            tabIndex={
              isSpin
                ? -1
                : downloadActionRef?.current?.classList?.contains("fadeIn")
                ? -1
                : fileActionOpen
                ? 0
                : -1
            }
            // !isOptionClicked ? 'none':'unset'
          />
          {showCancel && (
            <Button
              clickHandler={cancelFileHandler}
              cssStyle="btn-cancel"
              btnName="Cancel"
            />
          )}
        </div>
      </div>
    </section>
  );
};
