// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reportfile_report-modal__PDxZb {\n  position: absolute;\n  width: 95%;\n  height: 97%;\n  border-radius: 10px;\n  z-index: 200;\n}\n.reportfile_report-close-btn__JnzIb {\n  background: transparent;\n  box-shadow: unset;\n  color: red;\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  padding: 0;\n  border: 2px solid red;\n  border-radius: 50%;\n  padding: 2px;\n}\n.reportfile_report-form__ecQcS {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  width: 90%;\n  top: 40%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n.reportfile_form-submit-btn__bL81I {\n  font-size: 1.25em;\n  padding: 8px 20px;\n  max-width: 300px;\n  margin: 0 auto;\n}\n", "",{"version":3,"sources":["webpack://./src/components/report_file/reportfile.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,UAAU;EACV,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,UAAU;EACV,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,UAAU;EACV,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;AACA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".report-modal {\n  position: absolute;\n  width: 95%;\n  height: 97%;\n  border-radius: 10px;\n  z-index: 200;\n}\n.report-close-btn {\n  background: transparent;\n  box-shadow: unset;\n  color: red;\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  padding: 0;\n  border: 2px solid red;\n  border-radius: 50%;\n  padding: 2px;\n}\n.report-form {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  width: 90%;\n  top: 40%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n.form-submit-btn {\n  font-size: 1.25em;\n  padding: 8px 20px;\n  max-width: 300px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"report-modal": "reportfile_report-modal__PDxZb",
	"report-close-btn": "reportfile_report-close-btn__JnzIb",
	"report-form": "reportfile_report-form__ecQcS",
	"form-submit-btn": "reportfile_form-submit-btn__bL81I"
};
export default ___CSS_LOADER_EXPORT___;
