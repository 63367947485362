import React, { useContext } from "react";
import { FileContext } from "../../contextAPI/fileContext";


const CheckboxInput = ({ text, checked, onChange }) => {
  const {themeConfig,theme} = useContext(FileContext);
  return (
    <div style={{ display: "flex", alignItems: "center", marginLeft: '10px', fontSize: '25px' }}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        style={{ marginRight: "15px", transform: 'scale(1.5)'}}
      />
      <span style={{color: themeConfig[theme].textPrimary}}>{text}</span>
    </div>
  );
};

export default CheckboxInput;
