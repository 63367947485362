import { useContext } from "react";
import styles from "./hero.module.css";
import { FileContext } from "../../contextAPI";

export const Hero = ({ title, content, element = null }) => {
  const {themeConfig,theme} = useContext(FileContext)
  return (
    <section className={styles["hero-section"]}>
      {title && <h1 style={{color: themeConfig[theme].textPrimary}}>{title}</h1>}
      {element !== null ? element : null}
      <p style={{color: themeConfig[theme].textPrimary}}>{content}</p>
    </section>
  );
};
