import { useContext, useEffect, useState } from "react";
import { Download, RefreshCcw } from "react-feather";
import { Link } from "react-router-dom";
import { FileContext } from "../../contextAPI";
import styles from "./fileaction.module.css";
import { FileAction } from "./FileAction";
import Swal from "sweetalert2";

export const DownloadOrConvert = ({
  convertAPI,
  isSplitPDF = false,
  isEpub,
}) => {
  const fileContext = useContext(FileContext);
  const { isOptionClicked } = useContext(FileContext);
  // const [isSpin, setIsSpin] = useState(false);
  const [resultLocation, setResultLocation] = useState(false);
  const [fromPage, setFromPage] = useState(fileContext?.splitPage?.fromPage);
  const [toPage, setToPage] = useState(fileContext?.splitPage?.toPage);

  const { themeConfig, theme, setIsSpin } = useContext(FileContext);
  const currentLocation = window.location.href.split("/").pop();

  useEffect(() => {
    setToPage(fileContext?.splitPage?.totalPage);
  }, [fileContext?.splitPage?.totalPage]);
  // from page > 1 and to page < total page
  useEffect(() => {
    fileContext?.setSplitPage((prev) => ({
      ...prev,
      fromPage: fromPage,
      toPage: toPage,
    }));
    fileContext?.setConversion((prev) => ({
      ...prev,
      isConverted: false,
    }));
  }, [fromPage, toPage]);

  // setting the isConverted context false on first render
  useEffect(() => {
    fileContext?.setFile(null);
  }, []);

  const convertAPIHandler = () => {
    setIsSpin(true);

    const values = { file: fileContext?.file };

    //   calling convert api
    convertAPI(
      isSplitPDF
        ? {
            ...values,
            page1: fileContext?.splitPage?.fromPage,
            page2: fileContext?.splitPage?.toPage,
          }
        : isEpub
        ? { ...values, language: fileContext?.lang.value }
        : values
    )
      .then((data) => {
        // setting imgages docx or zip or pdf location

        const pdfDownloadLoacation = isSplitPDF
          ? data.results.splitted_pdf_path.split("=")
          : null;

        setResultLocation(
          isSplitPDF
            ? process.env.REACT_APP_API_SERVER +
                pdfDownloadLoacation[0] +
                "=" +
                process.env.REACT_APP_FTP_SERVER +
                pdfDownloadLoacation[1]
            : process.env.REACT_APP_FTP_SERVER +
                (isEpub
                  ? data.results.converted_docx_to_epub_path
                  : data.results.images_path)
        );

        // // hiding the spinner
        setIsSpin(false);

        fileContext?.setConversion((prev) => ({
          ...prev,
          isConverted: true,
        }));
        console.log('helo world')
        const audio = new Audio('/conversion_completed.mp3')
        audio.play();

        const url = window.location.pathname;
        switch (url) {
          case "/pdf-to-images":
            fileContext?.setConversion((prev) => ({
              ...prev,
              convertedFileLocation: data?.results?.file_name + ".zip ",
            }));
            break;
          case "/pdf-split":
            fileContext?.setConversion((prev) => ({
              ...prev,
              convertedFileLocation: data?.results?.pdf_file_name + ".pdf",
            }));
            break;
        }
      })
      .catch((e) => {
        // hiding the spinner
        setIsSpin(false);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: e.error,
        });

        console.error(e);
      });
  };

  return fileContext?.file !== null ? (
    <section className={styles["download-or__convert"]}>
      {!fileContext?.conversion?.isConverted ? (
        <div className={styles.convert}>
          {/* only for split pdf section */}
          {isSplitPDF && (
            <div className={styles["split-container"]}>
              <p
                style={{ color: themeConfig[theme].textPrimary, width: "100%" }}
              >
                Select from and to page you want to split
              </p>
              <div className={styles["split-pages"]}>
                <div className={styles["split-page__ip"]}>
                  <label htmlFor="from-page" aria-label="From Page" tabIndex={0}>From Page</label>
                  <input
                    type="number"
                    name="from-page"
                    inputMode="numeric"
                    id="from-page"
                    min={1}
                    max={fileContext?.splitPage?.totalPage}
                    value={fromPage}
                    onChange={function (e) {
                      const inputValue = parseInt(e.target.value);

                      if (isNaN(inputValue)) {
                        setFromPage(null);
                      } else if (inputValue < 1) {
                        //from page is not allowed to be less than 1
                        setFromPage(1);
                      } else if (inputValue >= fileContext?.splitPage?.toPage) {
                        // from page must be less than to page
                        setFromPage(+fileContext?.splitPage?.toPage - 1);
                      } else if (
                        inputValue > fileContext?.splitPage?.totalPage
                      ) {
                        //from page can not exceed the total pages
                        setFromPage(fileContext?.splitPage?.totalPage);
                      } else {
                        setFromPage(e.target.value);
                      }
                    }}
                  />
                </div>
                <div className={styles["split-page__ip"]}>
                  <label htmlFor="to-page" aria-label="To Page" tabIndex={0}>To Page</label>
                  <input
                    type="number"
                    name="to-page"
                    inputMode="numeric"
                    id="to-page"
                    value={toPage}
                    max={fileContext?.splitPage?.totalPage}
                    min={0}
                    onChange={(e) => {
                      const inputValue = parseInt(e.target.value);

                      if (isNaN(inputValue)) {
                        setToPage(null);
                      } else if (inputValue <= 1) {
                        //to page is not allowed to be less than 1
                        setToPage(1);
                      } else if (
                        inputValue <= fileContext?.splitPage?.fromPage
                      ) {
                        //to page must be greater than from page
                        setToPage(+fileContext?.splitPage?.fromPage + 1);
                      } else if (
                        inputValue > fileContext?.splitPage?.totalPage
                      ) {
                        //to page can not exceed the total page
                        setToPage(fileContext?.splitPage?.totalPage);
                      } else {
                        setToPage(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {isEpub && (
            <div className={styles["epub-select"]}>
              <FileAction
                title="Language"
                options={[
                  { name: "English", value: "eng" },
                  { name: "Nepali", value: "nep" },
                ]}
                selectType="lang"
              />
            </div>
          )}
          <button
            className={styles["btn-convert"]}
            onClick={convertAPIHandler}
            style={{
              pointerEvents:
                currentLocation === "pdf-to-images" ||
                currentLocation === "pdf-split"
                  ? ""
                  : isOptionClicked
                  ? "unset"
                  : "none",
            }}
          >
            <RefreshCcw />
            {isSplitPDF ? "Split" : "Convert"}
          </button>
          {/* <Spinner isSpin={isSpin} /> */}
        </div>
      ) : (
        <Link
          to={resultLocation}
          className={styles["download-btn"]}
          style={{ background: themeConfig[theme].btnQuarternary }}
          download={true}
          target={
            window.location.pathname === "/pdf-split" ? "_blank" : "_self"
          }
        >
          <Download strokeWidth={3} /> Download
        </Link>
      )}
    </section>
  ) : null;
};
