import { DownloadOrConvert, DropArea, Hero } from "../components";
import { splitPdf } from "../api";
import { useContext, useEffect } from "react";
import { FileContext } from "../contextAPI";

export const PdfSplit = () => {
  const {themeConfig,theme} = useContext(FileContext)
  useEffect(() => {
    document.title = "PDF Split - OLEN-iOCR";
  }, []);
  return (
    <>
      <Hero
        element={
          <>
            <h1 className="title-with__images">
              <img  src={themeConfig[theme].pdf_split} alt="PDF to Split" height="45em" />
              <span style={{color: themeConfig[theme].textPrimary,fontSize: '1.25em'}}>PDF Split</span>
            </h1>
          </>
        }
        content="PDF Split is a tool designed to divide single PDF documents into two or more separate PDF files as users direct the page number range. Dividing a PDF into sections (e.g., chapters, sections, or individual pages) can make it easier to navigate and manage large documents."
      />

      <DropArea isSplitPDF={true} />

      <DownloadOrConvert convertAPI={splitPdf} isSplitPDF={true} />
    </>
  );
};
