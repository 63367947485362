import AboutUsLogo from "../assets/aboutus_logo.png";
import "../../src/App.css";
import { useContext, useEffect } from "react";
import {FileContext} from '../contextAPI/fileContext'

export const AboutUS = () => {
  const {themeConfig,theme} = useContext(FileContext);
  useEffect(() => {
    document.title = "About Us- OLEN-iOCR";
  }, []);
  return (
    <section className="aboutus_container">
      <div className="aboutus_head">
        <h3 style={{color: themeConfig[theme].textPrimary}}>About US</h3>
      </div>
      <div className="logo_display">
        <img src={AboutUsLogo} alt="ole nepal logo" />
        <div style={{color: themeConfig[theme].textPrimary}}>OLEN-iOCR</div>
      </div>
      <div className="about_desc">
        <p style={{color: themeConfig[theme].textPrimary}}>
          Open Learning Exchange Nepal (OLE Nepal) is a social benefit
          organization dedicated to enhancing education quality and access
          through the integration of technology in classrooms. Since its
          inception in 2007, OLE Nepal has pioneered the use of technology in
          schools and provided open and free access to quality education and
          innovative learning environments to children.
          <br />
          iOCR is developed by OLE Nepal and supported by KDDI Foundation.
        </p>
      </div>
      <div className="contact_us_details" style={{border: `1px dashed ${themeConfig[theme].textPrimary}`}}>
        <div className="contact_us_head"  style={{color: themeConfig[theme].textPrimary}}>
          <h1>CONTACT US</h1>
        </div>
        <div className="contact_us_desc" style={{color: themeConfig[theme].textPrimary}}>
          <p>
            <span>Address:</span> Sanepa, Lalitpur
          </p>
          <p>
            <span>Phone:</span> (+977) 01-5444441, 01-5420075
          </p>
          <p>
            <span>Email:</span> info@olenepal.org
          </p>
          <p>
            <span>Post Box:</span> PO Box 8975, EPC 2394, Kathmandu, Nepal
          </p>
          <p>
            <span>Working hours:</span> Monday - Friday | 9:00 AM - 5:30 PM
          </p>
        </div>
      </div>
    </section>
  );
};
