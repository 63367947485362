import { useEffect, useState } from "react";
import { page_visit } from "../../api";
import styles from "./pagevisit.module.css";

export const PageVisitSphere = () => {
  const [visitCount, setvisitCount] = useState(1000);
  useEffect(() => {
    // initail api call
    page_visit()
      .then((data) =>
        console.log("Current page visit count: ", data.visitor_count)
      )
      // .then((data) => setvisitCount(data.visitor_count))
      .catch((e) => console.error(e));
  }, []);
  return null;
  // return (
  //   <section className={styles["cube-container"]}>
  //     <div className={styles.cube}>
  //       <div className={`${styles.side} ${styles.front}`}>
  //         <p>{visitCount}</p>
  //       </div>
  //       <div className={`${styles.side} ${styles.back}`}>
  //         <p>Page Visit</p>
  //       </div>

  //       <div className={`${styles.side} ${styles.bottom}`}></div>
  //       <div className={`${styles.side} ${styles.top}`}></div>
  //       <div className={`${styles.side} ${styles.left}`}></div>
  //       <div className={`${styles.side} ${styles.right}`}></div>
  //     </div>
  //   </section>
  // );
};
