// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layout_overlay__ceyhU {\n  background-color: rgb(255 255 255/ 0.2);\n  -webkit-backdrop-filter: blur(2px);\n          backdrop-filter: blur(2px);\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  inset: 0;\n  z-index: -1;\n}\n\n.layout_page-wrapper__029m\\+ {\n  display: grid;\n  min-height: 100vh;\n  grid-template-rows: 3.15em calc(100% - 5.15em) 2em;\n}\n.layout_faq-main__kqgR9{\n  justify-content: flex-start;\n  padding: 20px;\n  padding-top: 40px;\n}", "",{"version":3,"sources":["webpack://./src/components/layout/layout.module.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,kCAA0B;UAA1B,0BAA0B;EAC1B,YAAY;EACZ,aAAa;EACb,eAAe;EACf,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,kDAAkD;AACpD;AACA;EACE,2BAA2B;EAC3B,aAAa;EACb,iBAAiB;AACnB","sourcesContent":[".overlay {\n  background-color: rgb(255 255 255/ 0.2);\n  backdrop-filter: blur(2px);\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  inset: 0;\n  z-index: -1;\n}\n\n.page-wrapper {\n  display: grid;\n  min-height: 100vh;\n  grid-template-rows: 3.15em calc(100% - 5.15em) 2em;\n}\n.faq-main{\n  justify-content: flex-start;\n  padding: 20px;\n  padding-top: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "layout_overlay__ceyhU",
	"page-wrapper": "layout_page-wrapper__029m+",
	"faq-main": "layout_faq-main__kqgR9"
};
export default ___CSS_LOADER_EXPORT___;
