import { useContext, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import {
  FileText,
  Image,
  Paperclip,
  UploadCloud,
  Download,
  Eye,
} from "react-feather";
import { Link } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import Swal from "sweetalert2";

import { FileContext } from "../../contextAPI";
import styles from "./droparea.module.css";
import { ReactComponent as WordIconify } from "../../assets/WordIconify.svg";
// import pdfIcon from "../../assets/pdfIcon.png";
import { Spinner } from "../spinner";
import RefreshBtn from "../refresh";
import { ReactComponent as PdfIconified } from "../../assets/PdfIconify.svg";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const DropArea = ({ fileTypes = ["PDF"], isSplitPDF = false }) => {
  const fileContext = useContext(FileContext);
  const [convertedIcon, setConvertedIcon] = useState(null);
  const {
    themeConfig,
    theme,
    isSpin,
    downloadActionRef,
    viewRef,
    downloadRef,
    viewDownloadOpen
  } = useContext(FileContext);
  const handleChange = (file) => {
    fileContext?.setFile(file);
  };
  const clickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.location.reload();
  };
  useEffect(() => {
    // making the hidden input provided by react-drag-drop-files package inaccessible to Accessibility API
    document
      .querySelector("input[name='to-be__converted']")
      .setAttribute("aria-hidden", true);

    const url = window.location.pathname;
    switch (url) {
      case "/pdf-to-images":
        setConvertedIcon(
          <Image
            size={120}
            strokeWidth={0.5}
            stroke={themeConfig[theme].textPrimary}
          />
        );
        break;
      case "/pdf-split":
        setConvertedIcon(
          // <img
          //   src={pdfIcon}
          //   alt="pdf Icon"
          //   height="70%"
          //   style={{ marginTop: "5%" }}
          // />
          <PdfIconified />
        );
        break;
      default:
        setConvertedIcon(
          // <img
          //   src={wordIcon}
          //   alt="Microsoft Word Icon"
          //   height="70%"
          //   style={{ marginTop: "5%" }}
          // />
          <WordIconify />
        );
        break;
    }
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    fileContext?.setSplitPage((prev) => ({
      ...prev,
      toPage: numPages,
      totalPage: numPages,
    }));
  };

  return (
    <section
      className={`${styles["drop-container"]} ${isSplitPDF ? styles['height-container'] : ''}`}
      style={{
        backgroundColor: themeConfig[theme].boxPrimary,
        border: `0.1em dashed ${themeConfig[theme].boxSecondary}`,
      }}
      tabIndex={0}
    >
      {/* <RefreshBtn onClick={clickHandler} tabIndex="-1" /> */}
      <FileUploader
        handleChange={handleChange}
        name="to-be__converted"
        types={fileTypes}
        onTypeError={() =>
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Invalid file format!",
          })
        }
        tabIndex={-1}
      >
        {/* showing uploaded or upload */}
        {!fileContext?.file ? (
          <div className={styles.dropcontent} tabIndex="-1">
            <div className={styles.icon} tabIndex={-1}>
              <UploadCloud
                size={90}
                strokeWidth={0.5}
                style={{ color: themeConfig[theme].textPrimary }}
              />
            </div>
            <p style={{ color: themeConfig[theme].textPrimary }}>
              Drag and drop to convert
              <br />
              <span>or,</span>
            </p>
            <button
              className={styles["btn-select"]}
              style={{ backgroundColor: themeConfig[theme].btnPrimary }}
            >
              <Paperclip size={20} /> Browse File
            </button>
          </div>
        ) : !fileContext?.conversion?.isConverted ? ( // showing converted or upload
          <div className={`${styles.dropcontent} ${styles.uploaded}`} tabIndex={-1}>
            {!isSplitPDF ? (
              <>
                <div className={styles.icon}>
                  <FileText
                    size={120}
                    strokeWidth={0.5}
                    fill="#fff"
                    stroke="#813bc3"
                    className={styles["textfile-icon"]}
                  />
                </div>
                <p style={{ color: themeConfig[theme].textPrimary }}>
                  {fileContext?.file.name} <span>Uploaded</span>
                </p>
              </>
            ) : (
              // only for pdf split
              <section className={`${styles["pdf-viewer"]} ${isSplitPDF ? styles['pdfViewerSplitContainer'] : ''}`} tabIndex={-1}>
                <div className={styles["first-page"]} style={{color: themeConfig[theme].textPrimary}}>
                  <Document
                    file={fileContext?.file}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      pageNumber={parseInt(fileContext?.splitPage?.fromPage)}
                    />
                  </Document>
                  <p style={{color: themeConfig[theme].textPrimary}}>Page {fileContext?.splitPage?.fromPage}</p>
                </div>
                <div className={styles["last-page"]} style={{color: themeConfig[theme].textPrimary}}>
                  <Document
                    file={fileContext?.file}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      pageNumber={parseInt(fileContext?.splitPage?.toPage)}
                    />
                  </Document>
                  <p style={{color: themeConfig[theme].textPrimary}}>Page {fileContext?.splitPage?.toPage}</p>
                </div>
              </section>
            )}
          </div>
        ) : (
          // section for converted div
          <div className={styles.dropcontent}>
            <div
              className={`${styles.icon} ${styles.convertedIcon}`}
              style={{ stroke: themeConfig[theme].textPrimary }}
            >
              {convertedIcon}
            </div>
            <p style={{ color: themeConfig[theme].textPrimary }}>
              {fileContext?.conversion?.convertedFileLocation}
              <br />
              <strong>Converted</strong>
            </p>
          </div>
        )}
      </FileUploader>
      <RefreshBtn onClick={clickHandler} tabIndex="0" />
      <Spinner isSpin={isSpin} tabIndex="-1" />
      {/* View and upload button */}
      <div
        className={styles["download-view__container"]}
        ref={downloadActionRef}
      >
        <Link
          to="/view-converted-ocr"
          className={styles["view-btn"]}
          aria-hidden={true}
          ref={viewRef}
          style={{ background: themeConfig[theme].btnTertiary }}
          tabIndex={viewDownloadOpen ? 1 : -1}
        >
          <Eye
            className={styles["eyeIcon"]}
            fill="#fff"
            stroke="white"
            strokeWidth={2}
          />
          View
        </Link>

        <Link
          to={fileContext?.conversion?.docxLocation}
          className={styles["download-btn"]}
          download={true}
          aria-hidden={true}
          ref={downloadRef}
          style={{ background: themeConfig[theme].btnQuarternary }}
          tabIndex={viewDownloadOpen ? 1 : -1}
        >
          <Download strokeWidth={3} /> Download
        </Link>
      </div>
    </section>
  );
};
