import { useEffect, useRef } from "react";
import styles from "./spinner.module.css";

export const Spinner = ({ isSpin }) => {
  const spinnerRef = useRef();

  useEffect(() => {
    isSpin
      ? spinnerRef?.current.classList.add(styles["show-spinner"])
      : spinnerRef?.current.classList.remove(styles["show-spinner"]);
  }, [isSpin]);
  return <div className={styles.spinner} ref={spinnerRef} tabIndex={-1}></div>;
};
