import styles from "./navigation.module.css";
import { NavItem } from "./NavItem";
import { Menu, X } from "react-feather";
import { useContext } from "react";
import { FileContext } from "../../contextAPI";
import Sidebar from "../sidebar";
import { useNavigate } from "react-router-dom";

export const NavBar = () => {
  const {
    themeConfig,
    theme,
    toggleTheme,
    isSidebarOpen,
    setIsSidebarOpen,
    menuIconRef,
    menuListRef,
  } = useContext(FileContext);
  const navigate = useNavigate();

  const mobileMenuHandlerWithAnimation = () => {
    setIsSidebarOpen(!isSidebarOpen);
    menuIconRef.current.classList.toggle(styles["rotate-menu"]);
  };
  

  return (
    <header className={styles.header} style={{background: themeConfig[theme].backgroundPrimary}}>
      {isSidebarOpen && <Sidebar />}
      <nav>
        <div className={styles['nav-wrapper']}>
          <section className={styles.logo}>
            <div className={styles["image-container"]} onClick={() =>navigate('/')}>
              <img src={themeConfig[theme].logo} alt="OLEN-iOCR logo" />
            </div>
          </section>

          <div className={styles["menu-mobile"]} ref={menuIconRef}>
            {!isSidebarOpen ? (
              <Menu
                className={styles["menu-icon"]}
                onClick={mobileMenuHandlerWithAnimation}
                style={{ color: themeConfig[theme].textPrimary }}
              />
            ) : (
              <X
                className={styles["menu-icon"]}
                onClick={mobileMenuHandlerWithAnimation}
                style={{ color: themeConfig[theme].textPrimary }}
              />
            )}
          </div>

          <section className={styles["nav-links"]} ref={menuListRef}>
            <NavItem path="/" content="Home" />
            <NavItem path="/pdf-to-images" content="pdf to Images" />
            <NavItem path="/pdf-split" content="pdf Split" />
            <NavItem path="/word-to-epub" content="Word to E-Pub" />
            <NavItem path="/about" content="About Us" />
            <NavItem path="/contact-us" content="Contact Us" />
            <NavItem path="/faq" content="FAQ" />
            <button
              className={styles["btn"]}
              style={{ color: themeConfig[theme].textPrimary }}
              onClick={() => toggleTheme()}
              aria-label={theme === "dark" ? "light" : "dark"}
            >
              <img src={themeConfig[theme].bulb} alt="white bulb" />
            </button>
          </section>
        </div>
      </nav>
    </header>
  );
};
