import axios from "axios";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { convert_file } from "../../api";
import { FileContext } from "../../contextAPI";
import styles from "./viewfile.module.css";

export const ViewFile = ({
  title,
  imgarr = [],
  isConverted = false,
  element = null,
}) => {
  const fileContext = useContext(FileContext);
  const pageRefs = useRef([]);
  const currentPageRef = useRef(null);
  const { setModal, setImageSource, themeConfig, theme } =
    useContext(FileContext);
  const [reportFileImage, setReportFileImage] = useState("");

  useEffect(() => {
    const handleClick = async (index) => {
      const clickedImageUrl = pageRefs.current[index].src;

      currentPageRef.current.src = clickedImageUrl;

      setReportFileImage(clickedImageUrl);

      const img = await axios.get(pageRefs.current[index].src);

      // calling the conversion api
      convert_file({
        ["conversion-type"]: fileContext?.fileType.value,
        file: img,
        language: fileContext?.lang.value,
      })
        .then((data) => {
          fileContext?.setConversion((prev) => ({
            ...prev,
            isConverted: true,
            convertedFileLocation: data?.filename + ".docx",
            // setting converted docx location
            docxLocation:
              process.env.REACT_APP_FTP_SERVER + data?.results?.docx_file_path,
            // setting imgages location
            imgLocation: data?.results?.images?.map(
              (item) => process.env.REACT_APP_FTP_SERVER + item
            ),
          }));
        })
        .catch((e) => {
          console.error(e);
        });
    };

    pageRefs?.current?.forEach((ref, index) => {
      ref?.addEventListener("click", () => handleClick(index));
    });

    return () => {
      pageRefs?.current?.forEach((ref, index) => {
        ref?.removeEventListener("click", () => handleClick(index));
      });
    };
  }, [imgarr]);

  const modalFn = useCallback(() => {
    setModal(true);
    let trimmedUrl = reportFileImage
      ? reportFileImage?.replace(/^https:\/\/iocrfiles\.olenepal\.org/, "")
      : imgarr[0]?.replace(/^https:\/\/iocrfiles\.olenepal\.org/, "");
    setImageSource(trimmedUrl);
  }, [imgarr, reportFileImage]);

  return (
    <div className={styles["file-container"]}>
      <p className={styles.title} style={{color: themeConfig[theme].textPrimary}}> {title}</p>

      {isConverted ? (
        element
      ) : (
        <>
          <button
            className={styles["report-problem-click"]}
            style={{ background: themeConfig[theme].reportBackground }}
            onClick={modalFn}
          >
            Report Problems?
          </button>
          <div className={styles["pdf-viewer"]}>
            <img
              src={imgarr[0]}
              alt="Current Page"
              className={styles["current-page"]}
              ref={currentPageRef}
            />

            {/* only show slider if multpages */}
            {/* {imgarr.length !== 1 && ( */}
            <div className={styles.slider}>
              {imgarr?.map((item, index) => (
                <img
                  src={item}
                  alt={`page ${index + 1}`}
                  key={item + index}
                  ref={(el) => (pageRefs.current[index] = el)}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
