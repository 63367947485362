import React, { useContext, useEffect, useRef } from "react";
import styles from "./sidebar.module.css";
import { NavItem } from "../navigation/NavItem";
import { FileContext } from "../../contextAPI";

const Sidebar = () => {
  const sidebarRef = useRef(null);
  const {
    mobileMenuHandler,
    themeConfig,
    theme,
    toggleTheme,
    isSidebarOpen,
    setIsSidebarOpen,
  } = useContext(FileContext);
  const sidebarClass = isSidebarOpen
    ? `${styles["sidebar-links"]} ${styles["sidebar-links-visible"]}`
    : `${styles["sidebar-links"]}`;

  useEffect(() => {
    const handleSidebar = () => {
      if (window.innerWidth > 1000) {
        setIsSidebarOpen(false);
      }
    };
    window.addEventListener("resize", handleSidebar);
    return () => {
      window.removeEventListener("resize", handleSidebar);
    };
  }, [setIsSidebarOpen]);
  useEffect(() => {
    if (!sidebarRef.current) return;
    sidebarRef.current.style.background = `${themeConfig[theme].backgroundPrimary}`;
  }, [theme, themeConfig]);
  return (
    <section className={sidebarClass} ref={sidebarRef}>
      <NavItem mobileMenuHandler={mobileMenuHandler} path="/" content="Home" />
      <NavItem
        mobileMenuHandler={mobileMenuHandler}
        path="/pdf-to-images"
        content="pdf to Images"
      />
      <NavItem
        mobileMenuHandler={mobileMenuHandler}
        path="/pdf-split"
        content="pdf Split"
      />
      <NavItem
        mobileMenuHandler={mobileMenuHandler}
        path="/word-to-epub"
        content="Word to E-Pub"
      />
      <NavItem
        mobileMenuHandler={mobileMenuHandler}
        path="/about"
        content="About Us"
      />
      <NavItem
        mobileMenuHandler={mobileMenuHandler}
        path="/contact-us"
        content="Contact Us"
      />
      <NavItem path="/faq" content="FAQ" />
      <button
        className={styles["btn"]}
        style={{ color: themeConfig[theme].textPrimary }}
        onClick={() => toggleTheme()}
      >
        <img src={themeConfig[theme].bulb} alt="white bulb" />
      </button>
    </section>
  );
};

export default Sidebar;
