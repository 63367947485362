import React, { useContext } from "react";
import styles from "./input.module.css";
import { FileContext } from "../../contextAPI";

const Input = ({ label, inputType, onChange, err = "",name,value }) => {
  const { themeConfig, theme } = useContext(FileContext);
  return (
    <div className={styles["input-container"]}>
      <div style={{ color: themeConfig[theme].textPrimary }}>{label}</div>
      <input
        type={inputType}
        name={name}
        value={value}
        style={{
          background: themeConfig[theme].inputBackground,
          border: "none",
          color: themeConfig[theme].textPrimary,
        }}
        onChange={onChange}
      />
      {err && (
        <span
          style={{
            color: "red",
            display: "flex",
            fontSize: "0.7em",
          }}
        >
          {err}
        </span>
      )}
    </div>
  );
};

export default Input;
