import { useContext } from "react";

import { ViewFile, ViewFileActions } from "../../components";
import { FileContext } from "../../contextAPI";
import styles from "./viewconverted.module.css";
import { ArrowLeft } from "react-feather";
import { useNavigate } from "react-router-dom";
import ReportPageIssues from "../../components/report_file";

export const ViewConverted = () => {
  const fileLocationContenxt = useContext(FileContext);

  const { themeConfig, theme, modal } = useContext(FileContext);

  const navigate = useNavigate();

  function copyFileHandler() {
    const el = document.createElement("textarea");
    el.value = document.querySelector("iframe");
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  }

  return (
    <>
      <section
        className={styles["view-container"]}
        style={{ background: themeConfig[theme].viewBackground }}
        tabIndex={-1}
      >
        {modal && <ReportPageIssues />}
        <div className={styles["view-wrapper"]}>
          <ViewFile
            title="Original Document"
            imgarr={fileLocationContenxt?.conversion?.imgLocation}
            // imgarr={[
            //   "http://iocr.pustakalaya.org/tmp/tmpzlgfe42l/vyr4HX_test.jpg",
            //   "http://iocr.pustakalaya.org/tmp/tmpzlgfe42l/OEp64m_bibsha1.jpg",
            //   "http://iocr.pustakalaya.org/tmp/tmpzlgfe42l/vyr4HX_test.jpg",
            //   "http://iocr.pustakalaya.org/tmp/tmpzlgfe42l/OEp64m_bibsha1.jpg",
            //   "http://iocr.pustakalaya.org/tmp/tmpzlgfe42l/vyr4HX_test.jpg",
            //   "http://iocr.pustakalaya.org/tmp/tmpzlgfe42l/OEp64m_bibsha1.jpg",
            //   "http://iocr.pustakalaya.org/tmp/tmpzlgfe42l/vyr4HX_test.jpg",
            //   "http://iocr.pustakalaya.org/tmp/tmpzlgfe42l/OEp64m_bibsha1.jpg",
            //   "http://iocr.pustakalaya.org/tmp/tmpzlgfe42l/vyr4HX_test.jpg",
            //   "http://iocr.pustakalaya.org/tmp/tmpzlgfe42l/OEp64m_bibsha1.jpg",
            //   "http://iocr.pustakalaya.org/tmp/tmpzlgfe42l/vyr4HX_test.jpg",
            //   "http://iocr.pustakalaya.org/tmp/tmpzlgfe42l/OEp64m_bibsha1.jpg",
            // ]}
          />
          <ViewFile
            isConverted={true}
            title="Converted Document"
            element={
              <iframe
                src={
                  "https://view.officeapps.live.com/op/embed.aspx?src=" +
                  fileLocationContenxt?.conversion?.docxLocation
                  // "http://iocr.pustakalaya.org/tmp/tmpzlgfe42l/1d51Rw_test.docx"
                }
                width="100%"
                frameBorder="0"
                style={{
                  height: "56vh",
                }}
                tabIndex={-1}
                // data-custom-iframe="true"
              >
                This is an embedded
                <a target="_blank" href="http://office.com">
                  Microsoft Office
                </a>
                document, powered by{" "}
                <a target="_blank" href="http://office.com/webapps">
                  Office Online
                </a>
                .
              </iframe>
            }
          />
        </div>

        <div className={styles["report-download"]}>
          <div className={styles["report-container"]}>
            <button
              onClick={() => navigate("/")}
              aria-label="Back Home"
              style={{
                backgroundColor: themeConfig[theme].btnInfo,
                padding: "0.5em 0.5em",
              }}
              tabIndex={0}
            >
              <ArrowLeft aria-hidden="true" focusable="false" />
              <span className={styles["visually-hidden"]}>Back Home</span>
            </button>
            {/* <button className={styles["report-problem"]} style={{color: themeConfig[theme].textPrimary}}>Back Home</button> */}
          </div>
          <ViewFileActions copyFileHandler={copyFileHandler} />
        </div>
      </section>
    </>
  );
};
