// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input_input-container__g0gBw {\n  display: block;\n}\n.input_input-container__g0gBw > input {\n  width: 100%;\n  padding: 15px 15px;\n  border-radius: 10px;\n  font-size: 0.7em;\n}\n.input_input-container__g0gBw > div {\n  display: block;\n  text-align: left;\n  font-size: 0.8em;\n}\n.input_input-error__2VcjX{\n  color: red;\n  font-size: 0.7em;\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Input/input.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,UAAU;EACV,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".input-container {\n  display: block;\n}\n.input-container > input {\n  width: 100%;\n  padding: 15px 15px;\n  border-radius: 10px;\n  font-size: 0.7em;\n}\n.input-container > div {\n  display: block;\n  text-align: left;\n  font-size: 0.8em;\n}\n.input-error{\n  color: red;\n  font-size: 0.7em;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-container": "input_input-container__g0gBw",
	"input-error": "input_input-error__2VcjX"
};
export default ___CSS_LOADER_EXPORT___;
