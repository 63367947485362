import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Layout } from "./components";
import { FileContextAPI } from "./contextAPI";
import { Home, PdfSplit, PdfToImg, ViewConverted, WordToEpub } from "./pages";
import { AboutUS, ContactUs } from "./pages";
import {FAQPage} from "./pages";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<FileContextAPI />}>
          <Route path="" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/view-converted-ocr" element={<ViewConverted />} />
            <Route path="/about" element={<AboutUS />} />
            <Route path="/pdf-to-images" element={<PdfToImg />} />
            <Route path="/pdf-split" element={<PdfSplit />} />
            <Route path="/word-to-epub" element={<WordToEpub />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/faq" element={<FAQPage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default App;
