// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hero_hero-section__2kJwh {\n  /* margin: 5% auto 3%; */\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  /* margin-bottom: 20px; */\n}\n.hero_hero-section__2kJwh h1 {\n  font-size: 1.3em;\n  font-weight: 500;\n}\n\n.hero_hero-section__2kJwh p {\n  /* font-size: 1.2em; */\n  font-size: 0.59em;\n}\n\n@media (min-aspect-ratio: 4/3) {\n  .hero_hero-section__2kJwh p {\n    font-size: 0.8em;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/hero/hero.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".hero-section {\n  /* margin: 5% auto 3%; */\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  /* margin-bottom: 20px; */\n}\n.hero-section h1 {\n  font-size: 1.3em;\n  font-weight: 500;\n}\n\n.hero-section p {\n  /* font-size: 1.2em; */\n  font-size: 0.59em;\n}\n\n@media (min-aspect-ratio: 4/3) {\n  .hero-section p {\n    font-size: 0.8em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero-section": "hero_hero-section__2kJwh"
};
export default ___CSS_LOADER_EXPORT___;
